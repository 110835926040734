import React from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import {
  ContactWrapper,
  Citation,
  ThemedTextArea,
  SendBtn,
  MobileCitation,
  Galaxy,
} from "@Styles/contact";
import Input from "@Components/Input";
import { CONF } from "../conf";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { mapDataImages, retrieveImageFluid } from "../utils";
import { Theme } from "../styles/Theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { MDXRenderer } from "gatsby-plugin-mdx";

const Contact = ({ startTypingText, isMobile }) => {
  let contactData = useStaticQuery(graphql`
    query ImagesAndLegals {
      allFile(filter: { relativeDirectory: { eq: "galaxy" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 100) {
                base64
                originalImg
                aspectRatio
                sizes
                src
                srcSet
                originalName
              }
            }
          }
        }
      }
    }
  `);
  const dataImages = mapDataImages(contactData);

  const [nameError, setNameError] = React.useState(false);
  const [phoneError, setPhoneError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);
  const [galaxyIsVisible, setGalaxyIsVisible] = React.useState(false);

  const nameRef = React.useRef();
  const companyRef = React.useRef();
  const phoneRef = React.useRef();
  const emailRef = React.useRef();
  const messageRef = React.useRef();
  const citationRef = React.useRef(null);

  function submitForm(e) {
    e.preventDefault();
    let error = false;
    if (!nameRef.current.value) {
      error = true;
      setNameError(true);
    }
    if (!phoneRef.current.value) {
      error = true;
      setPhoneError(true);
    }
    if (!emailRef.current.value) {
      error = true;
      setEmailError(true);
    }
    if (!messageRef.current.value) {
      error = true;
      setMessageError(true);
    }
    if (error) return;

    //handle sending here

    const body = {
      name: nameRef.current.value.toString(),
      company: companyRef.current.value.toString(),
      phone: phoneRef.current.value.toString(),
      email: emailRef.current.value.toString(),
      message: messageRef.current.value.toString(),
    };

    const form = e.target;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...body,
      }),
    })
      .then(() => alert("success"))
      .catch((error) => alert(error));
  }

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }

  let interval;
  function autoTypeText(parentNode, str) {
    const arr = str.split("");
    let i = 0;
    let isPaused = false;
    interval = setInterval(() => {
      if (!isPaused) {
        if (arr[i] === "!") {
          isPaused = true;
          parentNode.appendChild(document.createElement("br"));
          i++;
          return setTimeout(() => (isPaused = false), 500);
        }
        parentNode.innerHTML += arr[i];
        i++;
        if (i >= arr.length) {
          clearInterval(interval);
        }
      }
    }, 50);
  }

  React.useEffect(() => {
    if (!isMobile) {
      if (startTypingText) {
        autoTypeText(citationRef.current, CONF.CONTACT_TEXT);
      } else {
        setGalaxyIsVisible(false);
        citationRef.current.innerHTML = null;
      }
    }
    return () => {
      clearInterval(interval);
    };
  }, [startTypingText]);

  return (
    <ContactWrapper>
      <Galaxy
        style={{
          width: isMobile ? "100vw" : "80%",
          height: isMobile ? "100vh" : "80%",
          top: isMobile ? "0" : "10%",
          left: isMobile ? "0" : "10%",
          zIndex: galaxyIsVisible ? 5000 : 0,
          opacity: galaxyIsVisible ? 1 : 0,
          position: isMobile ? "fixed" : "absolute",
        }}
      >
        <Img
          fluid={retrieveImageFluid(dataImages, "systeme_solaire.png")}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            objectFit: "cover",
          }}
        />
        <p
          style={{
            position: "absolute",
            top: "26.5%",
            left: "15.5%",
            color: "#fff",
            textTransform: "uppercase",
            fontSize: isMobile ? "24px" : "16px",
            fontWeight: "900",
          }}
        >
          Nous sommes basés
          <br />
          en <span style={{ color: Theme.colors.red }}>France</span> sur
          <br />
          la planète Terre
        </p>
        <Img
          fluid={
            isMobile
              ? retrieveImageFluid(dataImages, "ligne_rouge_mobile.png")
              : retrieveImageFluid(dataImages, "ligne_rouge_desktop.png")
          }
          style={{
            width: isMobile ? "60%" : "36%",
            position: "absolute",
            top: isMobile ? "45%" : "35%",
            left: "15%",
          }}
        />
        <FontAwesomeIcon
          icon={faTimesCircle}
          style={{
            position: "absolute",
            fontSize: "30px",
            color: Theme.colors.red,
            top: "30px",
            right: "30px",
            cursor: "pointer",
          }}
          onClick={() => setGalaxyIsVisible(false)}
        />
      </Galaxy>
      <Container>
        <Row>
          <Col
            xl={{ span: 5, offset: 1 }}
            style={{
              paddingTop: "150px",
              paddingLeft: isMobile ? "8rem" : "14rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Citation ref={citationRef} isMobile={isMobile}>
              <MobileCitation>
                Pas d'appel pas d'feeling
                <br />
                pas d'feeling pas d'contrat
                <br />
                pas d'contrat pas d'créa
                <br />
                pas d'créa...
                <br />
                <br />
                pas d'créa. ”
              </MobileCitation>
            </Citation>
            {!isMobile && (
              <div
                style={{
                  width: "150px",
                  margin: "0 auto",
                  display: isMobile ? "none" : "block",
                  cursor: "pointer",
                }}
                onClick={() => setGalaxyIsVisible(true)}
              >
                <Img
                  style={{ width: "100%" }}
                  fluid={retrieveImageFluid(dataImages, "pointer.png")}
                />
              </div>
            )}
          </Col>
          <Col xl={{ span: 3, offset: 1 }} style={{ paddingTop: "150px" }}>
            <form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={submitForm}
            >
              <input type="hidden" name="form-name" value="contact" />
              <Input
                type="text"
                ref={nameRef}
                placeholder={"nom *"}
                error={nameError}
                setError={setNameError}
                name={"name"}
              />
              <Input
                type="text"
                ref={companyRef}
                placeholder={"entreprise"}
                name={"company"}
              />
              <Input
                type="tel"
                ref={phoneRef}
                placeholder={"téléphone *"}
                error={phoneError}
                setError={setPhoneError}
                name={"phone"}
              />
              <Input
                type="email"
                ref={emailRef}
                placeholder={"e-mail *"}
                error={emailError}
                setError={setEmailError}
                name={"email"}
              />
              <ThemedTextArea
                ref={messageRef}
                placeholder="message *"
                className={messageError && "hasError"}
                onKeyUp={() => setMessageError(false)}
                name={"message"}
              ></ThemedTextArea>
              <SendBtn type="submit">Envoyer</SendBtn>
            </form>
            {isMobile && (
              <div
                style={{
                  width: "150px",
                  position: "static",
                  marginBottom: "150px",
                  margin: "100px auto 100px auto",
                  display: isMobile ? "block" : "none",
                  cursor: "pointer",
                }}
                onClick={() => setGalaxyIsVisible(true)}
              >
                <Img
                  style={{ width: "100%" }}
                  fluid={retrieveImageFluid(dataImages, "pointer.png")}
                />
              </div>
            )}
          </Col>
        </Row>
        <Link
          style={{
            width: 300,
            textAlign: "center",
            position: "absolute",
            bottom: 30,
            left: "50%",
            color: "#FFF",
            textDecoration: "none",
            transform: "translateX(-50%)",
            cursor: "pointer",
          }}
          to={`/legals`}
        >
          Mentions légales
        </Link>
      </Container>
    </ContactWrapper>
  );
};

export default Contact;
