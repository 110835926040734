import React from "react"
import styled from "styled-components"
import { Theme } from "@Styles/Theme"

const ThemedInput = styled.input`
  background: transparent;
  width: 100%;
  color: #fff;
  font-size: 2rem;
  border: none;
  border-bottom: solid 2px #fff;
  margin-bottom: 4rem;
  outline: none;
  padding-left: 1rem;

  &.hasError{
    border-bottom: solid 2px ${Theme.colors.red};
  }

  &:focus, &:active{
    background: transparent;
    border: none;
    outline: none;
    border-bottom: solid 2px #fff;
  }

  &::placeholder{
    color: #fff;
    font-size: 2rem;
    opacity: .5;
    text-transform: uppercase;
    @media (max-width: 1024px){
      font-size: 1.5rem;
    }
  }

  @media (max-width: 1024px){
    font-size: 1.5rem;
  }

`

const Input = ({
  type,
  required,
  placeholder,
  error,
  setError,
  name
}, ref) => {

  function handleError() {
    setError && setError(false)
  }

  return (
    <>
      <label htmlFor={name} hidden>{placeholder}</label>
      <ThemedInput
        ref={ref}
        type={type}
        placeholder={placeholder}
        required={required}
        className={error && 'hasError'}
        onKeyUp={handleError}
        name={name}
      />
    </>
  )
}

const forwardedInput = React.forwardRef(Input)
export default forwardedInput