import styled from "styled-components"
import { Theme } from "./Theme"

export const ContactWrapper = styled.div`
  position:  ${props => props.isMobile ? "relative" : "absolute"};
  z-index: 3000;
  min-height: 100vh;
  width: 100vw;
  background-color: ${Theme.colors.blue};
`

export const Citation = styled.div`
  font-size: 3rem;
  font-weight: 700;
  font-style: italic;
  letter-spacing: -1px;
  color: #fff;
  position: relative;
  quotes: "“" "”";

  &:before{
    content: open-quote;
    position: absolute;
    top: 0;
    font-size: 8rem;
    line-height: 8rem;
    left: -5rem;
  }

  @media (max-width: 1024){
    font-size: 1.4rem;
  }

  // &:after{
  //   content: close-quote;
  //   margin-left: 1rem;
  // }
`

export const ThemedTextArea = styled.textarea`
  background: transparent;
  width: 100%;
  height: 15vh;
  max-height: 200px;
  color: #fff;
  font-size: 2rem;
  border: none;
  border-bottom: solid 2px #fff;
  margin-bottom: 4rem;
  outline: none;
  padding-left: 1rem;

  &::placeholder{
    color: #fff;
    font-size: 2rem;
    opacity: .5;
    text-transform: uppercase;
    @media (max-width: 1024px){
      font-size: 1.5rem;
    }
  }

  @media (max-width: 1024px){
    font-size: 1.5rem;
  }

  &:focus, &:active{
    background: transparent;
    border: none;
    outline: none;
    border-bottom: solid 2px #fff;
  }

  &.hasError{
    border-bottom: solid 2px ${Theme.colors.red};
  }
`

export const SendBtn = styled.button`
  border: solid 2px #fff;
  background: transparent;
  padding: .75rem 1.6rem;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 1px;
  color: #fff;
  float: right;

  &:hover{
    background: #fff;
    color: ${Theme.colors.blue};
  }
`

export const MobileCitation = styled.div`
  display: none;
  @media (max-width: 1024px){
    display: block;
    font-size: 20px;
  }
`

export const Galaxy = styled.div`
  position: absolute;
  z-index: 0;
  opacity: 0;
  border-radius: 4px;
  overflow: hidden;
  transition: opacity .5s ease-out;
`
